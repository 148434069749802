import React from 'react';

const isPrerender = navigator.userAgent === "ReactSnap"

function ItemPopup(props) {

    function order() {

        let dop = 0

        // document.getElementsByName('checkbox').forEach(function (item) {
        //     if(item.checked) {dop = dop + Number(item.getAttribute('value2'))}
        // });

        const basketItem = props.basket.find((item)=> {
            // return item.id === props.item.id;
            return item.item.id === props.item.id;
        });

        if (basketItem) {
            basketItem.x = basketItem.x + 1
        }
        else {
            props.setBasket([...props.basket, 
                {
                    item: props.item,
                    x: 1,
                },
            ])
        }

        if(props.orderID){
            document.cookie = `order_id=;expires=${new Date(0)}`
            props.setOrderID('')
            window.localStorage.removeItem('paykeeper');
            window.localStorage.removeItem('prettyCart');
            window.localStorage.removeItem('createbinding');
        }

        props.setItem('')
    }

    React.useEffect(() => {
        document.forms.itemform.reset()
    }, [props.item]);

    return (
        <div 
        className={props.item ? 'popup popup_opened' : 'popup'} 
        onClick={(e)=>{if (e.target === document.getElementsByClassName('popup_opened')[0]) {props.setItem('')}}}
        >
            <div className="popup__container">
                <div className="popup__image-container">
                    {!isPrerender //  Без этого не работает react snap, почему-то не нравиться onError={(e) => {e.target.onerror = null; e.target.src = '../images/products/preview/1669889503118.jpeg'}} \/\/\/
                    ? <img 
                        src={`../images/products/preview/${props.item.picture}`} 
                        onError={(e) => {e.target.onerror = null; e.target.src = '../images/products/preview/1669889503118.jpeg'}} 
                        alt={props.item.title} 
                        className="popup__image">
                    </img>
                    : null
                    }
                </div>
                <div className="popup__item">
                    <h2 className="popup__item-name">{props.item.title}</h2>
                    { props.item.description 
                        ?
                            <p className="popup__item-info">
                            <span className="popup__item-info-span">Состав: </span>
                            {props.item.description}    
                            </p>
                        :
                            <p className="popup__item-info"></p>
                    }
                    <p className="popup__item-info">
                        <span className="popup__item-info-span">Размер порции: </span>
                        {props.item ? props.item.quantity.value : ''} гр   
                    </p>
                    <p className="popup__item-footnote">Вариант сервировки, фактическое блюдо может отличаться от изображения</p>
                    <form className="popup__item-form" name="itemform">
                        {/* <label className="popup__item-label">
                            <input id="osn" className="popup__item-checkbox" type="checkbox" name="checkbox" value="Первое" value2="75"></input>
                            <span className="popup__item-visible-checkbox"></span>
                            Первое
                        </label>
                        <label className="popup__item-label">
                            <input id="osn" className="popup__item-checkbox" type="checkbox" name="checkbox" value="Второе" value2="50"></input>
                            <span className="popup__item-visible-checkbox"></span>
                            Второе
                        </label>
                        <label className="popup__item-label">
                            <input id="osn" className="popup__item-checkbox" type="checkbox" name="checkbox" value="Третье" value2="25"></input>
                            <span className="popup__item-visible-checkbox"></span>
                            Третье 
                        </label> */}
                    </form>
                    <div className="popup__item-container">
                    <p className="popup__item-price">
                        <span className="popup__item-price-span">Цена: </span>
                        { props.item ? Math.round(props.item.price.value*props.item.multiplier) : ''}  руб.
                    </p>
                    {
                    props.checkbasket(props.item)
                    ? 
                    <div className="popup__item-buttons">
                        <button className="popup__item-portion-btn popup__item-portion-btn_remove" onClick={()=>{props.removePortion(props.basket.find((basketitem)=>{return basketitem.item.id === props.item.id}))}}>-</button>
                        <p className='popup__item-portion-x'>{props.checkbasket(props.item)?.x}</p>
                        <button className="popup__item-portion-btn popup__item-portion-btn_add" onClick={()=>{props.addPortion(props.basket.find((basketitem)=>{return basketitem.item.id === props.item.id}))}}>+</button>
                    </div>
                    :
                        <button className="popup__item-purchase" onClick={order} disabled={!props.item}>Заказать</button>
                    }
                    </div>
                </div>
                <i className="fa fa-times popup__close" aria-hidden="true" onClick={()=>{props.setItem('')}}></i>
            </div>
        </div>
    );
}
    
export default ItemPopup;