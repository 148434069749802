import React from 'react';
import { Swiper, SwiperSlide, useSwiper} from 'swiper/react';

const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return <div className='swiper-button-next arrow-review-next' onClick={() => swiper.slideNext()}></div>;
  };
  
  
  const SwiperButtonPrev = () => {
  const swiper = useSwiper();
  return <div className='swiper-button-prev arrow-review-prev' onClick={() => swiper.slidePrev()}></div>;
  };

function ComplexPopup(props) {

    const [index, setIndex] = React.useState(1);
    const [combo, setCombo] = React.useState({1:null,2:null,3:null,4:null});
 
    return (
        <div 
        className={props.isComplex ? 'popup popup_opened' : 'popup'} 
        onClick={(e)=>{if (e.target === document.getElementsByClassName('popup_opened')[0]) {props.setIsComplex(false)}}}
        >   { index !== 5
            ? 
            <div className="popup__complex">

                <h2 className='popup__complex-title'>Веберите {index === 1 ? "Первое" : index === 2 ? "Второе" : index === 3 ? "Гарнир" : "Салат"}</h2>
                
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                >
                    
                    {props.products.filter(p => p.sections_id === index).map((item) => {
                        return (
                        <SwiperSlide>
                        <div className='popup__complex-item'>
                            <img src={`images/products/preview/${item.picture}`} alt={item.title} className="popup__complex-image"></img>
                            <h2 className="popup__complex-name">{item.title}</h2>
                            {combo[index] === item.title
                            ?
                            <div className='popup__complex-picked'>Выбрано</div>
                            :
                            <button className='popup__complex-button' onClick={()=>{setCombo({...combo, [index] : item.title})}}>Выбрать</button>
                            }
                            {/* <button className='popup__complex-button' onClick={()=>{setCombo({...combo, [index] : item.title});}}>Выбрать</button> */}
                        </div>
                        </SwiperSlide>
                        );
                    })}
                    
                    <SwiperButtonNext></SwiperButtonNext>
                    <SwiperButtonPrev></SwiperButtonPrev>

                </Swiper>
                <div className='popup__complex-buttons'>
                    <button className='popup__complex-button'onClick={()=>{setIndex(index - 1)}} disabled={index === 1}>Назад</button>
                    {combo[1] && combo[2] && combo[3] && combo[4]
                    ? <button className='popup__complex-button'onClick={()=>{setIndex(5)}}>К составу</button>
                    : null
                    }
                    <button className='popup__complex-button' onClick={()=>{setIndex(index + 1)}} disabled={!combo[index]}>Далее</button>
                </div>

            </div>
            :
            <div className="popup__complex">
                <h2 className='popup__complex-title'>Состав обеда:</h2>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Первое: {combo[1]}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(1)}}>Заменить</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Второе: {combo[2]}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(2)}}>Заменить</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Гарнир: {combo[3]}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(3)}}>Заменить</button>
                </div>
                <div className='popup__complex-combo'>
                    <h3 className="popup__complex-name">Салат: {combo[4]}</h3>
                    <button className='popup__complex-button' onClick={()=>{setIndex(4)}}>Заменить</button>
                </div>
                <h3 className="popup__complex-name">Хлеб - 2 шт.</h3>

                <div className='popup__complex-buttons'>
                    <button className='popup__complex-button'onClick={()=>{setIndex(index - 1)}} disabled={index === 1}>Назад</button>
                    <p className="popup__complex-price">
                        <span className="popup__complex-price-span">Цена: </span>
                        300 руб.    
                    </p>
                    <button className='popup__complex-button'>Заказать</button>
                </div>
            </div>
            }

        </div>
    );
}
    
export default ComplexPopup;